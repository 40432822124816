<template>
  <div class="news gn-box-border">
    <div class="title gn-full-x gn-flex-center">公司动态</div>
    <div class="main">
      <div class="tabs">
        <a-tabs v-model='tabKey' default-active-key="0" @change="changeTab">
          <a-tab-pane
            v-for="(item, index) in list"
            :key="index.toString()"
            :tab="item.title"
          >
            <div class="content gn-box-border" style="padding-left: 17px">
              <a-row>
                <a-col
                  :span="8"
                  style="margin-bottom: 32px"
                  v-for="(item, index) in item.dataList"
                  :key='index'
                  @click="goDetail(item)"
                >
                  <a-card hoverable :bordered='false' class="card" style="width: 430px; background: #f8f8f8;">
                    <div class="gn-full gn-rela" style="height: 283px">
                      <img
                        class="gn-full"
                        :src="item.title_image"
                      />
                      <img
                        v-if='tabKey === "1"'
                        src="@/assets/images/newbazaarIntroduce/video.png"
                        class="gn-center"
                        style="width: 100px; height: 100px"
                      >
                    </div>
                    <a-card-meta :title="item.title" style="padding: 25px 18px">
                      <template slot="description">
                        <span class="time">{{item.create_time}}</span>
                      </template>
                    </a-card-meta>
                  </a-card>
                </a-col>
              </a-row>
            </div>
            <load-more :status='item.loadStatus' @load="loadMore"></load-more>
          </a-tab-pane>
          <div slot="tabBarExtraContent">
            <a-input-search
              placeholder="输入关键字"
              style="width: 310px; height: 40px"
              @search="onSearch"
            />
          </div>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleList } from '@/api/admin'

export default {
  name: 'News',
  data () {
    return {
      tabKey: '0',
      list: [
        {
          title: '公司新闻',
          page: 1,
          per_page: 9,
          dataList: [],
          loadStatus: 'loadmore'
        }
        // {
        //   title: '行业动态',
        //   page: 1,
        //   per_page: 9,
        //   dataList: [],
        //   loadStatus: 'loadmore'
        // },
        // {
        //   title: '市场巡展',
        //   page: 1,
        //   per_page: 9,
        //   dataList: [],
        //   loadStatus: 'loadmore'
        // }
      ],
      reqParams: {
        page: 1,
        per_page: 9,
        search_data: '', // 关键字搜索
        advisory_type: '1', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      }
    }
  },
  created () {
    this.getList()
    this.initData()
    const type = this.$route.query.type || ''
    if (type) {
      if (type === '0') {
        setTimeout(() => {
          this.renderAppointContent('0')
        }, 20)
      } else if (type === '1') {
        setTimeout(() => {
          this.renderAppointContent('1')
        }, 20)
      } else {
        setTimeout(() => {
          this.renderAppointContent('2')
        }, 20)
      }
    }
  },
  watch: {
    '$route.name' (routeName) {
      // 当前页面切换导航菜单
      this.mappingRouteName(routeName)
    },
    // 监听,当路由发生变化的时候执行
    $route: {
      handler: function (val, oldVal) {
        const type = val.query.type
        if (type) {
          if (type === '0') {
            setTimeout(() => {
              this.renderAppointContent('0')
            }, 20)
          } else if (type === '1') {
            setTimeout(() => {
              this.renderAppointContent('1')
            }, 20)
          } else {
            setTimeout(() => {
              this.renderAppointContent('2')
            }, 20)
          }
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    initData () {
      const { type } = this.$route.query
      if (type) {
        // 从新闻跳转过来
        this.renderAppointContent(type)
      } else {
        // 从其它导航菜单跳转过来
        const routeName = this.$route.name
        this.mappingRouteName(routeName)
      }
    },
    mappingRouteName (name) {
      if (name) {
        const fn = {
          information: () => this.renderAppointContent('0'), // 公司新闻
          industryDynamics: () => this.renderAppointContent('1'), // 行业动态
          videoShow: () => this.renderAppointContent('2') // 视频展示
        }[name]

        fn && fn()
      }
    },
    renderAppointContent (type) {
      this.tabKey = type === '0' ? '0' : '1'
      this.changeTab(type)
    },
    async getList () {
      const currentItem = this.list[this.tabKey]
      this.reqParams.page = currentItem.page
      this.reqParams.per_page = currentItem.per_page

      const { list, count } = await getArticleList(this.reqParams)

      if (currentItem.page == 1) {
        currentItem.dataList = list || []
      } else {
        currentItem.dataList = [...currentItem.dataList, ...list]
      }
      if (currentItem.dataList.length === 0) {
        currentItem.loadStatus = 'empty'
      } else if (currentItem.dataList.length >= count) {
        currentItem.loadStatus = 'nomore'
      } else {
        currentItem.loadStatus = 'loadmore'
      }
    },
    changeTab (key) {
      this.reqParams.advisory_type = key == 0 ? '1' : '3'
      this.list[this.tabKey].page = 1
      this.getList()
    },
    onSearch (val) {
      this.reqParams.search_data = val
      this.getList()
    },
    loadMore () {
      const currentItem = this.list[this.tabKey]

      if (currentItem.loadStatus === 'loading') return
      currentItem.loadStatus = 'loading'
      currentItem.page += 1
      setTimeout(() => {
        this.getList()
      }, 500)
    },
    goDetail (item) {
      console.log(item)
      if (item.advisory_type === '3') {
        this.$router.push({
          name: 'video',
          query: {
            type: item.advisory_type, // 1:公司新闻  2:行业动态  3:视频展示
            videoUrl: item.video,
            name: item.title,
            time: item.create_time
          }
        })
      } else {
        this.$router.push({
          name: 'newsDetail',
          query: {
            type: item.advisory_type, // 1:公司新闻  2:行业动态  3:视频展示
            title: item.title
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 24px;
    color: #333334;
  }
  .ant-tabs-ink-bar {
    background: #00a0ac;
  }
  .ant-card-meta-title {
    font-weight: 700;
    font-size: 18px;
    color: #333333;
  }
  .ant-card-body{
    padding: 0;
  }
}
</style>

<style scoped lang="scss">
.news {
  margin-top: 68px;
  padding: 45px 252px;
  .title {
    font-size: 48px;
    font-weight: 700;
    color: #333334;
  }
  .tabs {
    margin-top: 65px;
  }
  .content {
    padding: 42px 0;
    .card {
      .time {
        font-size: 15px;
        color: #333333;
        display: inline-block;
        margin-top: 10px;
      }
    }
  }
}
</style>
